const initState = {
  courses: [],
};

const courseReducer = (state = initState, action) => {
  switch (action.type) {
    case 'CREATE_COURSE':
      console.log('Curso Creado', action.course);
      return state;
    case 'CREATE_COURSE_ERROR':
      console.log('Error en Creación del Curso', action.err);
      return state;
    default:
      return state;
  }
};

export default courseReducer;
