const initState = {
  loading: false,
  reload: false,
  firebaseContactQuery: {
    collection: 'providers',
    where: ['managed', '==', false],
    limit: 4,
  },
  firebaseInfoQuery: {
    collection: 'providers',
    where: ['managedInfo', '==', false],
    limit: 4,
  },
};

const superUserReducer = (state = initState, action) => {
  switch (action.type) {
    case 'UPDATE_PROVIDERS_LIST':
      return {
        ...state,
        loading: false,
        reload: true,
      };
    case 'UPDATE_PROVIDER_START':
      return {
        ...state,
        loading: true,
      };
    case 'UPDATE_PROVIDER_CONTACTED':
      return {
        ...state,
        loading: false,
        reload: true,
      };
    case 'LOAD_MORE_CONTACT':
      return {
        ...state,
        firebaseContactQuery: {
          ...state.firebaseContactQuery,
          limit: state.firebaseContactQuery.limit + 4,
        },
      };
    case 'UPDATE_PROVIDER_INFO_DOWNLOADED':
      return {
        ...state,
        loading: false,
        reload: true,
      };
    case 'LOAD_MORE_INFO':
      return {
        ...state,
        firebaseInfoQuery: {
          ...state.firebaseInfoQuery,
          limit: state.firebaseInfoQuery.limit + 4,
        },
      };

    default:
      return state;
  }
};

export default superUserReducer;
