// Add Items to Cart
export const addToCart = (productToAdd) => {
  return (dispatch) => {
    dispatch({
      type: 'ADD_TO_CART',
      payload: productToAdd,
    });

    dispatch({ type: 'UPDATE_TOTAL' });
  };
};

// Increment ProductCount
export const incrementProductCount = (productId) => {
  return (dispatch) => {
    dispatch({
      type: 'INCREMENT_PRODUCT_COUNT',
      payload: productId,
    });

    dispatch({ type: 'UPDATE_TOTAL' });
  };
};

// Decrement ProductCount
export const decrementProductCount = (productId) => {
  return (dispatch) => {
    dispatch({
      type: 'DECREMENT_PRODUCT_COUNT',
      payload: productId,
    });

    dispatch({ type: 'UPDATE_TOTAL' });
  };
};

// Remove Product From Cart
export const removeItem = (productId) => {
  return (dispatch) => {
    dispatch({
      type: 'REMOVE_ITEM',
      payload: productId,
    });

    dispatch({ type: 'UPDATE_TOTAL' });
  };
};

// Apply Discount
// export const applyDiscount = (discount) => {
//   return (dispatch) => {
//     dispatch({
//       type: 'APPLY_DISCOUNT',
//     });
//   };
// };

// Save Cart to Firebase
export const completePurchase = (cart, productPerProvider) => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    const { profile } = getState().firebase;
    const buyerId = getState().firebase.auth.uid;

    dispatch({ type: 'CART_SAVE_START' });

    firestore
      .collection('shoppingCarts')
      .add({
        ...cart,
        buyerFirstName: profile.firstName,
        buyerLastName: profile.lastName,
        buyerId,
        createdAt: new Date(),
        purchaseStatus: {},
      })
      .then((res) => {
        productPerProvider.forEach((notification) => {
          firestore
            .collection('providerNotifications')
            .add({
              providerOwnerId: notification[0],
              products: notification[1],
              shoppingCartId: res.id,
              buyerDetails: cart.buyerDetails,
              status: 'Pendiente',
              notificationStatus: 'Pendiente',
              progress: 0,
              buyerFirstName: profile.firstName,
              buyerLastName: profile.lastName,
              buyerId,
              createdAt: new Date(),
            })
            .then(() => {
              console.log('Notification Creada');
              dispatch({ type: 'PROVIDER_NOTIFICATION_SUCCESS' });
            })
            .catch((error) => {
              console.log(error);
              dispatch({ type: 'PROVIDER_NOTIFICATION_ERROR' });
            });
        });

        dispatch({ type: 'CART_SAVE_SUCCESS' });
        console.log('Se ha guardado el carrito');
      })
      .catch((error) => {
        dispatch({ type: 'CART_SAVE_ERROR', payload: error });
        console.log(error);
      });
  };
};

// Load Mora Shopping Carts
export const loadMoreShoppingCarts = () => {
  return (dispatch) => {
    dispatch({
      type: 'LOAD_MORE_SHOPPING_CARTS',
    });
  };
};
