import { combineReducers } from 'redux';
import { firestoreReducer } from 'redux-firestore';
import { firebaseReducer } from 'react-redux-firebase';
import authReducer from './authReducer';
import cartReducer from './cartReducer';
import courseReducer from './courseReducer';
import homeReducer from './homeReducer';
import locationReducer from './locationReducer';
import notificationsReducer from './notificationsReducer';
import projectReducer from './projectReducer';
import providerReducer from './providerReducer';
import publicProvidersReducer from './publicProvidersReducer';
import reviewReducer from './reviewReducer';
import searchReducer from './searchReducer';
import subscriberReducer from './subscriberReducer';
import superUserReducer from './superUserReducer';
import userReducer from './userReducer';

const rootReducer = combineReducers({
  auth: authReducer,
  cart: cartReducer,
  course: courseReducer,
  firestore: firestoreReducer,
  firebase: firebaseReducer,
  home: homeReducer,
  location: locationReducer,
  notifications: notificationsReducer,
  project: projectReducer,
  provider: providerReducer,
  publicProviders: publicProvidersReducer,
  review: reviewReducer,
  search: searchReducer,
  subscriber: subscriberReducer,
  superUser: superUserReducer,
  user: userReducer,
});

export default rootReducer;
