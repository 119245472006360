/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { LinkContainer } from 'react-router-bootstrap';
import LatLon from 'geodesy/latlon-spherical';
import TextTruncate from 'react-text-truncate';

// Bootstrap Imports
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image';
import Spinner from 'react-bootstrap/Spinner';

// Rating Import
import Rating from 'react-rating';

// FontAwesome Imports
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartPlus, faStar } from '@fortawesome/free-solid-svg-icons';
import { faStar as faStarEmpty } from '@fortawesome/free-regular-svg-icons';

// Firebase Imports
import firebase, { storage } from '../../config/fbConfig';

// Redux Imports
import { addToCart } from '../../store/actions/cartActions';

const Hit = (props) => {
  // Props Destructuring
  const { addToCart, hit, userLocation } = props;

  // Component State
  const [productImageUrl, setProductImageUrl] = useState('');
  const [providerDiscountValue, setProviderDiscountValue] = useState('');
  const [productPriceWithDiscount, setProductPriceWithDiscount] = useState('');
  const [productToAdd, setProductToAdd] = useState({});
  const [addImageToProduct, setAddImageToProduct] = useState(false);
  const [addBtnReady, setAddBtnReady] = useState(false);

  // Component Hooks
  useEffect(() => {
    Object.keys(hit).length !== 0 &&
      firebase
        .firestore()
        .collection('discounts')
        .where('providerId', '==', hit.providerId)
        .get()
        .then((res) => {
          if (res.docs.length === 0) {
            setProductToAdd({
              authorId: hit.authorId,
              productId: hit.objectID,
              providerId: hit.providerId,
              productName: hit.productName,
              productDescription: hit.productDescription,
              productCount: 1,
              productState: 'En proceso',
              productPrice: hit.productPrice,
              productTotalCost: hit.productPrice,
            });
            setAddImageToProduct(true);
          } else {
            res.forEach((doc) => {
              const discount = doc.data();

              setProviderDiscountValue(discount.discountValue);

              setProductPriceWithDiscount(
                (
                  (parseFloat(hit.productPrice) *
                    (100 - parseFloat(discount.discountValue))) /
                  100
                ).toFixed(2)
              );

              setProductToAdd({
                authorId: hit.authorId,
                productId: hit.objectID,
                providerId: hit.providerId,
                productName: hit.productName,
                productDescription: hit.productDescription,
                productCount: 1,
                productState: 'En proceso',
                productPrice: (
                  (parseFloat(hit.productPrice) *
                    (100 - parseFloat(discount.discountValue))) /
                  100
                ).toFixed(2),
                productTotalCost: (
                  (parseFloat(hit.productPrice) *
                    (100 - parseFloat(discount.discountValue))) /
                  100
                ).toFixed(2),
              });
            });
            setAddImageToProduct(true);
          }
        });
  }, []);

  useEffect(() => {
    Object.keys(productToAdd).length !== 0 &&
      storage
        .ref('product-images')
        .child(`${hit.productImage}_660x440`)
        .getDownloadURL()
        .then((res) => {
          setProductImageUrl(res);

          setProductToAdd({
            ...productToAdd,
            productImageUrl: res,
          });
          setAddBtnReady(true);
        });
  }, [addImageToProduct]);

  const userLoc =
    Object.keys(userLocation).length !== 0 &&
    new LatLon(userLocation.lat.toString(), userLocation.lng.toString());

  const providerLocation =
    Object.keys(userLocation).length !== 0 &&
    new LatLon(hit._geoloc.lat, hit._geoloc.lng);

  const distanceToProvider =
    Object.keys(userLocation).length !== 0 &&
    (userLoc.distanceTo(providerLocation) / 1000).toFixed(1);

  const starRating = hit.avgRating / 5;

  return (
    <Card
      style={{
        border: 'none',
        borderRadius: 15,
        maxWidth: 400,
        maxHeight: 101,
        boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
      }}
    >
      {providerDiscountValue && (
        <div
          style={{ filter: 'drop-shadow(-1px 3px 2px rgba(50, 50, 0, 0.5))' }}
        >
          <div
            className="d-flex justify-content-center"
            style={{
              position: 'absolute',
              left: 10,
              backgroundColor: 'var(--primary)',
              height: 35,
              width: 50,
              padding: '3px 3px 5px 3px',
              color: '#fff',
              fontWeight: 500,
              clipPath: 'polygon(100% 0, 100% 100%, 50% 80%, 0% 100%, 0% 0%)',
            }}
          >
            -{providerDiscountValue}%
          </div>
        </div>
      )}
      <Card.Body className="d-flex p-2">
        <div
          className="d-flex align-items-center justify-content-center mr-2"
          style={{ minWidth: 85, height: 85 }}
        >
          {!productImageUrl ? (
            <Spinner
              animation="grow"
              className="mx-auto"
              style={{
                color: 'var(--primary)',
              }}
            />
          ) : (
            <LinkContainer
              to={`/providers/provider/product/${hit.objectID}`}
              style={{ borderRadius: 10 }}
            >
              <Image
                src={productImageUrl}
                height={85}
                width={85}
                style={{ borderRadius: 10, objectFit: 'cover' }}
              />
            </LinkContainer>
          )}
        </div>
        <div className="pl-2" style={{ width: '100%' }}>
          <Card.Title
            className="pr-1 mb-1"
            style={{ fontSize: '1rem', color: 'var(--secondary)' }}
          >
            <LinkContainer to={`/providers/provider/product/${hit.objectID}`}>
              <TextTruncate
                line={1}
                style={{
                  width: '100%',
                  fontWeight: 500,
                }}
                truncateText="…"
                text={hit.productName}
              />
            </LinkContainer>
          </Card.Title>
          <LinkContainer
            to={`/providers/provider/product/${hit.objectID}`}
            className="d-flex justify-content-between"
            style={{
              width: '100%',
              color: '#737373',
              fontSize: 12,
              fontWeight: 500,
              flex: 3,
            }}
          >
            <div style={{ width: '100%', flex: 3 }}>
              <TextTruncate
                line={2}
                style={{
                  width: '100%',
                  color: '#737373',
                  fontSize: 12,
                  fontWeight: 500,
                  flex: 3,
                }}
                truncateText="…"
                text={hit.productDescription}
              />
              {Object.keys(userLocation).length !== 0 && (
                <div
                  style={{
                    flex: 0.8,
                    fontSize: 12,
                    textAlign: 'center',
                    fontWeight: 500,
                    color: 'var(--secondary)',
                    minWidth: 45,
                  }}
                >
                  a {distanceToProvider} Km
                </div>
              )}
            </div>
          </LinkContainer>
          <div
            style={{
              position: 'absolute',
              bottom: 6,
              right: 10,
              minWidth: 200,
            }}
          >
            <div className="d-flex align-items-ceter justify-content-between mt-1">
              <div className="d-flex align-items-center justify-content-center">
                <div className="d-flex">
                  <Rating
                    placeholderRating={starRating}
                    stop={1}
                    readonly
                    fractions={2}
                    emptySymbol={
                      <FontAwesomeIcon
                        icon={faStarEmpty}
                        style={{
                          color: 'var(--gray)',
                          fontSize: 14,
                        }}
                      />
                    }
                    placeholderSymbol={
                      <FontAwesomeIcon
                        icon={faStar}
                        style={{
                          color: 'var(--warning)',
                          fontSize: 14,
                        }}
                      />
                    }
                    fullSymbol={
                      <FontAwesomeIcon
                        icon={faStar}
                        style={{
                          color: 'var(--warning)',
                          fontSize: 14,
                        }}
                      />
                    }
                  />
                  <div
                    className="d-flex align-items-center ml-2"
                    style={{ color: 'var(--gray-dark)' }}
                  >
                    {hit.avgRating && (
                      <span style={{ fontSize: 14 }}>
                        {hit.avgRating.toFixed(2)}
                      </span>
                    )}
                  </div>
                </div>
                {providerDiscountValue && (
                  <span
                    className="ml-2"
                    style={{
                      color: 'var(--secondary)',
                      textDecoration: 'line-through',
                      fontSize: 10,
                      fontWeight: 'bold',
                    }}
                  >
                    $ {hit.productPrice}
                  </span>
                )}
                <span
                  className={providerDiscountValue ? 'ml-1' : 'ml-2'}
                  style={{
                    color: 'var(--primaryImportant)',
                    fontSize: 12,
                    fontWeight: 'bold',
                  }}
                >
                  ${' '}
                  {providerDiscountValue
                    ? productPriceWithDiscount
                    : hit.productPrice}
                </span>
              </div>
              <div
                className={
                  addBtnReady
                    ? 'btn-add-to-cart d-flex align-items-center justify-content-center px-3'
                    : 'btn-add-to-cart d-flex align-items-center justify-content-center btn-disabled px-3'
                }
                onClick={() => addToCart(productToAdd)}
              >
                <FontAwesomeIcon
                  icon={faCartPlus}
                  style={{
                    fontSize: 12,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addToCart: (productToAdd) => dispatch(addToCart(productToAdd)),
  };
};

export default connect(null, mapDispatchToProps)(Hit);
