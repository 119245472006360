import React from 'react';

// Bootstrap Imports
import Spinner from 'react-bootstrap/Spinner';

const LoadingView = (props) => {
  // Props destructuring
  const { action, text } = props;

  return (
    <div
      className="d-flex align-items-center justify-content-center flex-column text-center"
      style={{ height: '100%' }}
    >
      <strong className="mb-3" style={{ color: 'var(--primary)' }}>
        {action}...
      </strong>
      {text && (
        <p className="mb-3" style={{ color: 'var(--primary)' }}>
          {text}
        </p>
      )}
      <Spinner
        animation="grow"
        className="mx-auto"
        style={{
          color: 'var(--primary)',
        }}
      />
    </div>
  );
};

export default LoadingView;
