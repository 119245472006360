const initState = {
  product: {},
  modalShow: false,
  limit: 8,
  limitProducts: 8,
};

const publicProvidersReducer = (state = initState, action) => {
  switch (action.type) {
    case 'SET_PRODUCT_MODAL':
      return {
        ...state,
        product: action.payload,
      };
    case 'SET_MODAL_SHOW':
      return {
        ...state,
        modalShow: !state.modalShow,
      };

    case 'LOAD_MORE_PROVIDERS':
      return {
        ...state,
        limit: state.limit + 8,
      };
    case 'LOAD_MORE_PRODUCTS':
      return {
        ...state,
        limitProducts: state.limitProducts + 8,
      };

    default:
      return state;
  }
};

export default publicProvidersReducer;
