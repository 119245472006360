/* eslint-disable no-case-declarations */
const initState = {
  itemsInCart: 0,
  subTotal: 0,
  discount: 0,
  dicountApplied: false,
  total: 0,
  products: [],
  cartSaveError: null,
  loading: false,
  limit: 8,
};

const cartReducer = (state = initState, action) => {
  switch (action.type) {
    case 'ADD_TO_CART':
      const itemExist = state.products.find(
        (product) => product.productId === action.payload.productId
      );

      if (itemExist) {
        itemExist.productCount += 1;
        itemExist.productTotalCost = (
          itemExist.productPrice * itemExist.productCount
        ).toFixed(2);

        return {
          ...state,
          itemsInCart: state.itemsInCart + 1,
          subTotal: state.subTotal + parseFloat(itemExist.productPrice),
        };
      }
      return {
        ...state,
        itemsInCart: state.itemsInCart + 1,
        products: [...state.products, action.payload],
        subTotal: state.subTotal + parseFloat(action.payload.productPrice),
      };

    case 'INCREMENT_PRODUCT_COUNT':
      const itemToIcrement = state.products.find(
        (product) => product.productId === action.payload
      );

      itemToIcrement.productCount += 1;
      itemToIcrement.productTotalCost = (
        itemToIcrement.productPrice * itemToIcrement.productCount
      ).toFixed(2);

      return {
        ...state,
        itemsInCart: state.itemsInCart + 1,
        subTotal: state.subTotal + parseFloat(itemToIcrement.productPrice),
      };

    case 'DECREMENT_PRODUCT_COUNT':
      const itemToDecrement = state.products.find(
        (product) => product.productId === action.payload
      );

      itemToDecrement.productCount -= 1;
      itemToDecrement.productTotalCost = (
        itemToDecrement.productPrice * itemToDecrement.productCount
      ).toFixed(2);

      return {
        ...state,
        itemsInCart: state.itemsInCart > 0 && state.itemsInCart - 1,
        subTotal: state.subTotal - parseFloat(itemToDecrement.productPrice),
      };

    case 'REMOVE_ITEM':
      const itemToRemove = state.products.find(
        (product) => product.productId === action.payload
      );

      return {
        ...state,
        itemsInCart: state.itemsInCart - itemToRemove.productCount,
        products: state.products.filter(
          (product) => product.productId !== itemToRemove.productId
        ),
        subTotal: state.subTotal - parseFloat(itemToRemove.productTotalCost),
      };

    case 'UPDATE_TOTAL':
      return {
        ...state,
        total: state.subTotal - state.discount,
      };
    case 'PROVIDER_NOTIFICATION_START':
    case 'CART_SAVE_START':
      return {
        ...state,
        // loading: true,
      };
    case 'CART_SAVE_SUCCESS':
      return {
        itemsInCart: 0,
        subTotal: 0,
        discount: 0,
        dicountApplied: false,
        total: 0,
        products: [],
        cartSaveError: null,
        loading: false,
      };
    case 'PROVIDER_NOTIFICATION_SUCCESS':
      return {
        ...state,
        loading: false,
      };
    case 'PROVIDER_NOTIFICATION_ERROR':
    case 'CART_SAVE_ERROR':
      return {
        ...state,
        cartSaveError:
          'Ha ocurrido un error. Por favor vuelve a intentarlo en unos minutos.',
        loading: false,
      };
    case 'LOAD_MORE_SHOPPING_CARTS':
      return {
        ...state,
        limit: state.limit + 8,
      };
    default:
      return state;
  }
};

export default cartReducer;
