const initState = {
  showHomeModal: false,
};

const homeReducer = (state = initState, action) => {
  switch (action.type) {
    case 'CLOSE_HOME_MODAL':
      return { ...state, showHomeModal: false };
    default:
      return state;
  }
};

export default homeReducer;
