import React, { Component } from 'react';
import { connectInfiniteHits } from 'react-instantsearch-dom';
import PropTypes from 'prop-types';

// Bootstrap Imports
import Container from 'react-bootstrap/Container';

// Local Imports
import Hit from './Hit';
import LoadingView from '../layout/LoadingView';

class InfiniteHits extends Component {
  static propTypes = {
    hits: PropTypes.arrayOf(PropTypes.object).isRequired,
    hasMore: PropTypes.bool.isRequired,
    refine: PropTypes.func.isRequired,
  };

  sentinel = null;

  onSentinelIntersection = (entries) => {
    const { hasMore, refine } = this.props;

    entries.forEach((entry) => {
      if (entry.isIntersecting && hasMore) {
        refine();
      }
    });
  };

  componentDidMount() {
    this.observer = new IntersectionObserver(this.onSentinelIntersection);

    this.observer.observe(this.sentinel);
  }

  componentWillUnmount() {
    this.observer.disconnect();
  }

  render() {
    const { hits, searchVisible, userLocation } = this.props;

    return (
      <div className="ais-InfiniteHits">
        <div
          className={
            searchVisible
              ? 'new-margin-top-providers-search'
              : 'margin-top-providers-search'
          }
          style={{ paddingBottom: '5rem' }}
        >
          <ul className="ais-InfiniteHits-list">
            <Container className="providers-new-grid-view">
              {!hits ? (
                <div className="content-my-providers">
                  <LoadingView action="Cargando Productos" />
                </div>
              ) : (
                hits.map((hit) => (
                  <li key={hit.objectID} className="ais-InfiniteHits-item">
                    <Hit hit={hit} userLocation={userLocation} />
                  </li>
                ))
              )}
              <li
                className="ais-InfiniteHits-sentinel"
                ref={(c) => (this.sentinel = c)}
              />
            </Container>
          </ul>
        </div>
      </div>
    );
  }
}

export default connectInfiniteHits(InfiniteHits);
