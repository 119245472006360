const initState = {
  providers: [],
  products: [],
  loading: false,
  reloadImage: false,
  limit: 8,
  hasProviders: false,
};

const prodiverReducer = (state = initState, action) => {
  switch (action.type) {
    case 'SET_HAS_PROVIDERS':
      return {
        ...state,
        hasProviders: true,
      };
    case 'PROVIDER_ACTION_START':
      return {
        ...state,
        loading: true,
      };
    case 'CREATE_PROVIDER':
      // console.log('Negocio Creado', action.provider);
      return {
        ...state,
        loading: false,
      };
    case 'CREATE_PROVIDER_ERROR':
      // console.log('Error en Creación del Negocio', action.err);
      return {
        ...state,
        loading: false,
      };
    case 'CREATE_PRODUCT':
      // console.log('Producto Creado', action.product);
      return {
        ...state,
        loading: false,
      };
    case 'CREATE_PRODUCT_ERROR':
      // console.log('Error en Creación del Producto', action.err);
      return {
        ...state,
        loading: false,
      };
    case 'LOAD_MORE':
      return {
        ...state,
        limit: state.limit + 8,
      };
    case 'SET_RELOAD_IMAGE':
      return {
        ...state,
        reloadImage: !state.reloadImage,
      };

    default:
      return state;
  }
};

export default prodiverReducer;
