import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/analytics';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/messaging';
import 'firebase/storage';

const firebaseConfig = {
  apiKey: 'AIzaSyAd43cip_W_f_Z-9kECHQUhGksCZfn7YfM',
  authDomain: 'quedate-en-casa-ae2e8.firebaseapp.com',
  databaseURL: 'https://quedate-en-casa-ae2e8.firebaseio.com',
  projectId: 'quedate-en-casa-ae2e8',
  storageBucket: 'quedate-en-casa-ae2e8.appspot.com',
  messagingSenderId: '468926719760',
  appId: '1:468926719760:web:1c1f0c8215453eb9ce2795',
  measurementId: 'G-EB224603WW',
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();
firebase.firestore();
firebase.functions();
// firebase.messaging();

const storage = firebase.storage();

export { storage, firebase as default };
