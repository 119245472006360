/* eslint-disable import/no-named-as-default */
import React from 'react';
import { connect } from 'react-redux';
import algoliasearch from 'algoliasearch';
import { InstantSearch, SearchBox, Configure } from 'react-instantsearch-dom';

// Bootstrap Imports
import Spinner from 'react-bootstrap/Spinner';

// Local Imports
// eslint-disable-next-line import/no-named-as-default-member
import InfiniteHits from './InfiniteHits';

// Algolia Imports
import { ALGOLIA_APP_ID, ALGOLIA_SEARCH_KEY } from '../../config/AlgoliaApi';

const searchClient = algoliasearch(ALGOLIA_APP_ID, ALGOLIA_SEARCH_KEY);

const SearchResults = (props) => {
  // Props Destructuring
  const { userLocation, userLocationString } = props;

  return (
    <InstantSearch indexName="veciProducts" searchClient={searchClient}>
      <Configure
        hitsPerPage={10}
        filters="providerCategory:Restaurantes"
        aroundLatLng={userLocationString}
      />
      <div
        className="d-flex align-items-center fixed-top breadcrumb-separation px-3"
        style={{ paddingBottom: 10, paddingTop: 15 }}
      >
        <div className="page-title-without-arrow text-center">
          <SearchBox
            translations={{ placeholder: '¿Qué estás buscando?' }}
            showLoadingIndicator
            loadingIndicator={
              <Spinner
                animation="grow"
                className="mx-auto mt-4 mb-2"
                style={{
                  color: 'var(--primary)',
                }}
              />
            }
          />
        </div>
      </div>

      <InfiniteHits minHitsPerPage={10} userLocation={userLocation} />
    </InstantSearch>
  );
};

const mapStateToProps = (state) => {
  return {
    userLocation: state.location.userLocation,
    userLocationString: state.location.stringCords,
  };
};

export default connect(mapStateToProps)(SearchResults);
