import React from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { connect } from 'react-redux';

// Bootstrapp Imports
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Navbar from 'react-bootstrap/Navbar';

// Local Imports
import SignedInLinks from './SignedInLinks';
import SignedOutLinks from './SignedOutLinks';
import StartScreen from '../start/StartScreen';
// import Search from '../search/Search';
// import AlgoliaInstantSearch from '../search/AlgoliaInstantSearch';

const MenuBar = (props) => {
  // Props destructuring
  const { auth, profile } = props;

  // User Validation
  const links = auth.uid ? (
    <SignedInLinks profile={profile} />
  ) : (
    <SignedOutLinks />
  );

  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        variant="dark"
        fixed="top"
        className="bg-navbar-web"
        style={{ zIndex: 5000, height: 65 }}
      >
        <Container className="d-flex flex-nowrap align-items-center justify-content-center">
          <LinkContainer
            to="/"
            style={{
              maxWidth: 90,
              marginTop: 5,
              marginBottom: 5,
              cursor: 'pointer',
            }}
          >
            <Image
              src="/img/logo-texto.png"
              alt="veci-online"
              rounded
              style={{
                width: 100,
                height: 36,
                marginTop: 5,
                marginBottom: 5,
              }}
            />
          </LinkContainer>
          {/* <Search /> */}
          {/* <AlgoliaInstantSearch /> */}
          {/* <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse
          id="responsive-navbar-nav"
          className="justify-content-between"
        >
          {links}
        </Navbar.Collapse> */}
        </Container>
      </Navbar>
      <StartScreen />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  };
};

export default connect(mapStateToProps)(MenuBar);
