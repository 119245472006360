// Manage Notification
export const notificationManaged = (notificationId) => {
  return (dispatch, getState, { getFirestore }) => {
    dispatch({
      type: 'NOTIFICATION_MANAGE_START',
    });

    const firestore = getFirestore();
    const { profile } = getState().firebase;
    const authorId = getState().firebase.auth.uid;

    firestore
      .collection('superUserNotifications')
      .doc(notificationId)
      .update({
        contactStatus: 'Contactado',
        managedBy: `${profile.firstName} ${profile.lastName}`,
        staffUid: authorId,
        updatedAt: new Date(),
      })
      .then(() => {
        dispatch({
          type: 'NOTIFICATION_MANAGED',
        });
      })
      .catch((error) => {
        dispatch({
          type: 'NOTIFICATION_MANAGED_ERROR',
          payload: error,
        });
      });
  };
};

// Reset Notifications Count on Signout
export const resetNotificationsCount = () => {
  return (dispatch) => {
    dispatch({
      type: 'RESET_NOTIFICATIONS_COUNT',
    });
  };
};

// Display More Notifications
export const setViewMoreSuperUserNotifications = () => {
  return (dispatch) => {
    dispatch({
      type: 'SET_VIEW_MORE_SUPER_USER_NOTIFICATIONS',
    });
  };
};

// Set Super User Total Notifications
export const setSuperUserTotalNotifications = (superUserTotalNotifications) => {
  return (dispatch) => {
    dispatch({
      type: 'SET_SUPER_USER_TOTAL_NOTIFICATIONS',
      payload: superUserTotalNotifications,
    });
  };
};

// Load More Notifications
export const loadMoreSuperUserNotifications = () => {
  return (dispatch) => {
    dispatch({
      type: 'LOAD_MORE_SUPER_USER_NOTIFICATIONS',
    });
  };
};

// Reset Notifications Limit
export const resetSuperUSerNotificationsLimit = () => {
  return (dispatch) => {
    dispatch({
      type: 'RESET_SUER_USER_NOTIFICATIONS_LIMIT',
    });
  };
};

export const setPoviderNotifications = (providerNotifications) => {
  return (dispatch) => {
    dispatch({
      type: 'SET_PROVIDER_NOTIFICATIONS',
      payload: providerNotifications,
    });
  };
};

export const setPurchaseNotifications = (purcahseNotifications) => {
  return (dispatch) => {
    dispatch({
      type: 'SET_PURCHASE_NOTIFICATIONS',
      payload: purcahseNotifications,
    });
  };
};
