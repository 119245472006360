/* eslint-disable no-case-declarations */
const initState = {
  loading: false,
  viewMoreSuperUserNotifications: false,
  superUserTotalNotifications: 0,
  superUserNotificationsLimit: 6,
  myProvidersNotificationsLimit: 4,
  myProviderNotifications: [],
  MyPurchasesNotificationsLimit: 4,
  purchaseNotifications: [],
};

const notificationsReducer = (state = initState, action) => {
  switch (action.type) {
    case 'NOTIFICATION_MANAGE_START':
      return {
        ...state,
        loading: true,
      };
    case 'NOTIFICATION_MANAGED':
      return {
        ...state,
        loading: false,
      };
    case 'NOTIFICATION_MANAGED_ERROR':
      console.log(action.payload);
      return {
        ...state,
        loading: false,
      };
    case 'RESET_NOTIFICATIONS_COUNT':
      return {
        ...state,
        superUserTotalNotifications: 0,
      };
    case 'SET_VIEW_MORE_SUPER_USER_NOTIFICATIONS':
      return {
        ...state,
        viewMoreSuperUserNotifications: !state.viewMoreSuperUserNotifications,
      };
    case 'SET_SUPER_USER_TOTAL_NOTIFICATIONS':
      return {
        ...state,
        superUserTotalNotifications: action.payload,
      };
    case 'LOAD_MORE_SUPER_USER_NOTIFICATIONS':
      return {
        ...state,
        superUserNotificationsLimit: state.superUserNotificationsLimit + 4,
      };
    case 'RESET_SUER_USER_NOTIFICATIONS_LIMIT':
      return {
        ...state,
        superUserNotificationsLimit: 6,
      };
    case 'SET_PROVIDER_NOTIFICATIONS':
      return {
        ...state,
        myProviderNotifications: action.payload,
      };
    case 'SET_PURCHASE_NOTIFICATIONS':
      return {
        ...state,
        purchaseNotifications: action.payload,
      };
    default:
      return state;
  }
};

export default notificationsReducer;
