import React from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

// import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Nav from 'react-bootstrap/Nav';
// import NavDropdown from 'react-bootstrap/NavDropdown';

// Redux Import
import { signOut } from '../../store/actions/authActions';

const SigniedInLinks = (props) => {
  const {
    // userRoleAdmin,
    profile,
    signOut,
  } = props;
  // console.log(props);

  // Hooks
  const history = useHistory();

  // Component Functions
  const exit = () => {
    signOut();
    history.push('/');
  };

  return (
    <>
      <Nav />
      <Nav>
        <DropdownButton
          alignRight
          variant="secondary"
          title={`${profile.firstName}`}
          id="dropdown-menu-align-right"
        >
          <Dropdown.Header>Opciones</Dropdown.Header>
          <LinkContainer to="/account">
            <Dropdown.Item eventKey="1">Mi Cuenta</Dropdown.Item>
          </LinkContainer>
          <Dropdown.Divider />
          <Dropdown.Item onClick={exit}>Cerrar Sesión</Dropdown.Item>
        </DropdownButton>
      </Nav>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => dispatch(signOut()),
  };
};

export default connect(null, mapDispatchToProps)(SigniedInLinks);
