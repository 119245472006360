import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import { LinkContainer } from 'react-router-bootstrap';

// Bootstrap Imports
import Badge from 'react-bootstrap/Badge';

// FontAwesome Imports
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBell,
  faSearch,
  faShoppingCart,
  faStore,
  faTag,
  faUser,
} from '@fortawesome/free-solid-svg-icons';

// Firebase Imports
import firebase from '../../config/fbConfig';

// Redux Imports
import {
  setPurchaseNotifications,
  setPoviderNotifications,
  setSuperUserTotalNotifications,
} from '../../store/actions/notificationsActions';
import { setMyProviders } from '../../store/actions/userActions';

const Footer = (props) => {
  // Props Destructuring
  const {
    auth,
    cartProducts,
    itemsInCart,
    profile,
    providerNotifications,
    purchaseNotifications,
    setPoviderNotifications,
    setSuperUserTotalNotifications,
    superUserNotifications,
    superUserTotalNotifications,
  } = props;

  // Component State
  // const [providerNotifications, setPoviderNotifications] = useState({});
  const [pendingNotifications, setPendingNotifications] = useState([]);

  // Component Hooks
  useEffect(() => {
    profile.isSuperUser &&
      firebase
        .firestore()
        .collection('superUserNotifications')
        .where('contactStatus', '==', 'Pendiente de contactar')
        .get()
        .then((snap) => {
          setSuperUserTotalNotifications(snap.size);
        });
  }, [superUserNotifications]);

  useEffect(() => {
    setPoviderNotifications(providerNotifications);
  }, [auth.uid, providerNotifications]);

  useEffect(() => {
    providerNotifications &&
      setPendingNotifications(
        providerNotifications
          .sort((a, b) => b.createdAt.seconds - a.createdAt.seconds)
          .filter((element) => element.status === 'Pendiente')
      );
  }, [providerNotifications]);

  return (
    <div
      className="d-flex align-items-center justify-content-center footer m-0"
      style={{ zIndex: 9999 }}
    >
      <div
        className="d-flex align-items-center justify-content-around text-center"
        style={{ width: '100%', maxWidth: 450 }}
      >
        <LinkContainer
          to="/promos"
          className="d-flex flex-column align-items-center justify-content-center bottom-nav mx-1"
          activeClassName="bottom-nav-active"
          style={{ flex: 1, position: 'relative' }}
        >
          <div>
            <div className="nav-indicator" />
            <FontAwesomeIcon
              icon={faTag}
              style={{
                fontSize: 20,
              }}
            />
            <span className="mt-1">Cupones</span>
          </div>
        </LinkContainer>
        <LinkContainer
          to="/providers"
          className="d-flex flex-column align-items-center justify-content-center bottom-nav mx-1"
          activeClassName="bottom-nav-active"
          style={{ flex: 1, position: 'relative' }}
        >
          <div>
            <div className="nav-indicator" />
            <FontAwesomeIcon
              icon={faStore}
              style={{
                fontSize: 20,
              }}
            />
            <span className="mt-1">Restaurantes</span>
          </div>
        </LinkContainer>
        <LinkContainer
          to="/search"
          className="d-flex flex-column align-items-center justify-content-center bottom-nav mx-1"
          activeClassName="bottom-nav-active"
          style={{ flex: 1, position: 'relative' }}
        >
          <div>
            <div className="nav-indicator" />
            <FontAwesomeIcon
              icon={faSearch}
              style={{
                fontSize: 23,
              }}
            />
            <span className="">Buscar</span>
          </div>
        </LinkContainer>
        <LinkContainer
          to="/account"
          className="d-flex flex-column align-items-center justify-content-center bottom-nav mx-1"
          activeClassName="bottom-nav-active"
          style={{ flex: 1, position: 'relative' }}
        >
          <div>
            <div className="nav-indicator" />
            <FontAwesomeIcon
              icon={faUser}
              style={{
                fontSize: 20,
              }}
            />
            <span className="mt-1">Cuenta</span>
            {
              // superUserTotalNotifications !== 0 ||
              pendingNotifications && pendingNotifications.length > 0 && (
                <Badge
                  pill
                  className="d-flex align-items-center justify-content-center"
                  style={{
                    position: 'absolute',
                    top: -5,
                    right: -3,
                    backgroundColor: 'red',
                    color: '#fff',
                    fontSize: 12,
                    minHeight: 21,
                    height: 21,
                    minWidth: 21,
                    width: 21,
                    boxShadow:
                      '0 2px 2px rgba(0, 0, 0, 0.2), 0 2px 4px rgba(0, 0, 0, 0.15)',
                  }}
                >
                  <FontAwesomeIcon
                    icon={faBell}
                    style={{
                      fontSize: 12,
                    }}
                  />
                </Badge>
              )
            }
          </div>
        </LinkContainer>

        <LinkContainer
          to="/purchase-cart"
          className="d-flex flex-column align-items-center justify-content-center bottom-nav mx-1"
          activeClassName="bottom-nav-active"
          style={{ flex: 1, position: 'relative' }}
        >
          <div>
            <div className="nav-indicator" />
            <FontAwesomeIcon
              icon={faShoppingCart}
              style={{
                fontSize: 20,
              }}
            />
            <span className="mt-1">Carrito</span>
            {itemsInCart !== 0 && (
              <Badge
                pill
                className="d-flex align-items-center justify-content-center"
                style={{
                  position: 'absolute',
                  top: -5,
                  right: -3,
                  backgroundColor: 'red',
                  color: '#fff',
                  fontSize: 12,
                  minHeight: 21,
                  height: 21,
                  minWidth: 21,
                  width: 21,
                  boxShadow:
                    '0 2px 2px rgba(0, 0, 0, 0.2), 0 2px 4px rgba(0, 0, 0, 0.15)',
                }}
              >
                {itemsInCart}
              </Badge>
            )}
          </div>
        </LinkContainer>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const {
    providerNotifications,
    purchaseNotifications,
    superUserNotifications,
  } = state.firestore.ordered;

  return {
    auth: state.firebase.auth,
    cartProducts: state.cart.products,
    itemsInCart: state.cart.itemsInCart,
    profile: state.firebase.profile,
    providerNotifications,
    purchaseNotifications,
    superUserNotifications,
    superUserTotalNotifications:
      state.notifications.superUserTotalNotifications,
    superUserNotificationsLimit:
      state.notifications.superUserNotificationsLimit,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSuperUserTotalNotifications: (superUserTotalNotifications) =>
      dispatch(setSuperUserTotalNotifications(superUserTotalNotifications)),
    setPoviderNotifications: (providerNotifications) =>
      dispatch(setPoviderNotifications(providerNotifications)),
    setPurchaseNotifications: (purchaseNotifications) =>
      dispatch(setPurchaseNotifications(purchaseNotifications)),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => {
    const globalNotifications = {
      collection: 'globalNotifications',
      where: ['isActive', '==', true],
      limit: 4,
    };

    // if (!props.profile.isSuperUser) {
    //   return [globalNotifications];
    // }

    // const ifSuperUser = {
    //   collection: 'superUserNotifications',
    //   where: ['contactStatus', '==', 'Pendiente de contactar'],
    //   limit: props.superUserNotificationsLimit,
    // };

    if (!props.auth.uid) {
      return [globalNotifications];
    }

    const providerNotifications = {
      collection: 'providerNotifications',
      where: ['providerOwnerId', '==', props.auth.uid],
      limit: 10,
    };

    const shoppingCarts = {
      collection: 'shoppingCarts',
      where: ['buyerId', '==', props.auth.uid],
    };

    return [globalNotifications, providerNotifications, shoppingCarts];
  })
)(Footer);
