// Signin
export const signIn = (credentials) => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();

    dispatch({ type: 'AUTH_START' });

    firebase
      .auth()
      .signInWithEmailAndPassword(credentials.email, credentials.password)
      .then(() => {
        dispatch({ type: 'LOGIN_SUCCESS' });
      })
      .catch((err) => {
        dispatch({ type: 'LOGIN_ERROR', err });
      });
  };
};

// Sign Out
export const signOut = () => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();

    firebase
      .auth()
      .signOut()
      .then(() => {
        dispatch({ type: 'SIGNOUT_SUCCESS' });
      });
  };
};

// Crear Custom User Profile
const customUserProfile = (userUID, newUser) => {
  return ({ getFirestore }) => {
    const firestore = getFirestore();

    console.log(userUID);

    firestore
      .collection('users')
      .doc(userUID)
      .set({
        firstName: newUser.firstName,
        lastName: newUser.lastName,
        initials: newUser.firstName[0] + newUser.lastName[0],
        email: newUser.email,
        userImageUrl: '',
        isProvider: false,
        isSuperUser: false,
        isAdmin: false,
        subscriptionExpiration: '',
        createdAt: new Date(),
      });

    console.log('Usuario debería haberse creado desde la función');
  };
};

// Sign Up
export const signUp = (newUser) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();

    dispatch({ type: 'AUTH_START' });

    firebase
      .auth()
      .createUserWithEmailAndPassword(newUser.email, newUser.password)
      .then((resp) => {
        resp.user.updateProfile({
          displayName: `${newUser.firstName} ${newUser.lastName}`,
        });
        return firestore
          .collection('users')
          .doc(resp.user.uid)
          .set({
            firstName: newUser.firstName,
            lastName: newUser.lastName,
            initials: newUser.firstName[0] + newUser.lastName[0],
            email: newUser.email,
            userImageUrl: '',
            isProvider: false,
            isSuperUser: false,
            isAdmin: false,
            subscriptionExpiration: '',
            createdAt: new Date(),
          });
      })
      .then(() => {
        dispatch({ type: 'SIGNUP_SUCCESS' });
      })
      .catch((err) => {
        dispatch({ type: 'SIGNUP_ERROR', err });
      });
  };
};
