/* eslint-disable import/prefer-default-export */
// Close Modal Home
export const setShowStartModal = () => {
  return (dispatch) => {
    dispatch({ type: 'SET_SHOW_MODAL' });
  };
};

export const setUserLocation = (position) => {
  return (dispatch) => {
    dispatch({
      type: 'SET_USER_LOCATION',
      payload: position,
    });
  };
};

export const locationPermissionsDenegated = () => {
  return (dispatch) => {
    dispatch({ type: 'SET_PERMISSIONS_DENIED' });
  };
};
