const initState = {
  userLocation: {},
  stringCords: '',
  showStartModal: false,
};

const locationReducer = (state = initState, action) => {
  switch (action.type) {
    case 'SET_USER_LOCATION':
      return {
        ...state,
        userLocation: {
          lat: action.payload.coords.latitude,
          lng: action.payload.coords.longitude,
        },
        stringCords: `${action.payload.coords.latitude.toString()},${action.payload.coords.longitude.toString()}`,
        showStartModal: false,
      };
    case 'SET_SHOW_MODAL':
      return { ...state, showStartModal: true };
    case 'SET_PERMISSIONS_DENIED':
      return { ...state, showStartModal: false };
    default:
      return state;
  }
};

export default locationReducer;
