/* eslint-disable no-param-reassign */
import React, { Suspense, lazy, useState } from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import ScrollToTopRoute from './ScrollToTopRoute';
import './App.css';

// Firebase Imports
import firebase from './config/fbConfig';

// import HomeLayout from './components/website/home/HomeLayout';
import MenuBar from './components/layout/Navbar';
import Footer from './components/layout/Footer';
import SearchResults from './components/search/SearchResults';

// Lazy Components Import
const Account = lazy(() => import('./components/account/Account'));
const Cart = lazy(() => import('./components/purchase/Cart'));
const DownloadProvidersInfo = lazy(() =>
  import('./components/superUser/DownloadProvidersInfo')
);
const Home = lazy(() => import('./components/home/Home'));
const ManageProviders = lazy(() =>
  import('./components/superUser/ManageProviders')
);
const ManageUsers = lazy(() => import('./components/admin/ManageUsers'));
const MyProviders = lazy(() => import('./components/providers/MyProviders'));
const MyPurchases = lazy(() => import('./components/purchases/MyPurchases'));
const MySales = lazy(() => import('./components/sales/MySales'));
const Notifications = lazy(() =>
  import('./components/notifications/Notifications')
);
const Page404 = lazy(() => import('./components/layout/Page404'));
const PasswordReset = lazy(() => import('./components/auth/PasswordReset'));
const PrivacyPolicies = lazy(() =>
  import('./components/website/privacyPolicies/PrivacyPolicies')
);
const ProductDetails = lazy(() =>
  import('./components/products/ProductDetails')
);
const ProductFullView = lazy(() =>
  import('./components/businesses/products/ProductFullView')
);
const Promos = lazy(() => import('./components/promos/Promos'));
const CuponsProviderFullView = lazy(() =>
  import('./components/cupons/CuponsProviderFullView')
);
const ProviderDetails = lazy(() =>
  import('./components/providers/ProviderDetails')
);
const ProviderFullView = lazy(() =>
  import('./components/businesses/ProviderFullView')
);
const ProvidersSearchList = lazy(() =>
  import('./components/businesses/ProvidersSearchList')
);
const PurchaseFullView = lazy(() =>
  import('./components/purchases/PurchaseFullView')
);
const SaleFullView = lazy(() => import('./components/sales/SaleFullView'));
const SignIn = lazy(() => import('./components/auth/SignIn'));
const SignUp = lazy(() => import('./components/auth/SignUp'));

const App = () => {
  // Firebase Cloud messaging
  // const messaging = firebase.messaging();
  // messaging
  //   .requestPermission()
  //   .then(() => {
  //     console.log('Permisos Consedidos');
  //     return messaging.getToken();
  //   })
  //   .then((token) => {
  //     console.log(token);
  //   })
  //   .catch((error) => {
  //     console.log(error);
  //   });
  //
  // messaging.onMessage((payload) => {
  //   console.log('onMassage: ', payload);
  // });

  return (
    <Router>
      <div className="App">
        <MenuBar />
        <div className="app-layout">
          <Switch>
            <Route exact path="/">
              <Redirect to="/search" />
            </Route>
            <ScrollToTopRoute path="/search" component={SearchResults} />
            <ScrollToTopRoute exact path="/inicio" component={Home} />

            <Suspense fallback={<div>Cargando...</div>}>
              <ScrollToTopRoute exact path="/account" component={Account} />

              <ScrollToTopRoute
                exact
                path="/account/download-info-providers"
                component={DownloadProvidersInfo}
              />
              <ScrollToTopRoute
                exact
                path="/account/manage-providers"
                component={ManageProviders}
              />
              <ScrollToTopRoute
                exact
                path="/account/my-providers"
                component={MyProviders}
              />
              <ScrollToTopRoute
                exact
                path="/account/my-purchases"
                component={MyPurchases}
              />
              <ScrollToTopRoute
                exact
                path="/account/my-sales"
                component={MySales}
              />
              <ScrollToTopRoute
                path="/account/manage-users"
                component={ManageUsers}
              />
              <ScrollToTopRoute
                path="/account/my-purchases/:id"
                component={PurchaseFullView}
              />
              <ScrollToTopRoute
                path="/account/my-sales/:id"
                component={SaleFullView}
              />
              <ScrollToTopRoute
                path="/account/notifications"
                component={Notifications}
              />
              <ScrollToTopRoute
                path="/account/password-reset"
                component={PasswordReset}
              />
              <ScrollToTopRoute
                path="/account/privacy-policies"
                component={PrivacyPolicies}
              />
              <ScrollToTopRoute
                path="/account/product/:id"
                component={ProductDetails}
              />
              <ScrollToTopRoute
                path="/account/provider/:id"
                component={ProviderDetails}
              />
              <ScrollToTopRoute path="/account/signin" component={SignIn} />
              <ScrollToTopRoute path="/account/signup" component={SignUp} />
              <ScrollToTopRoute path="/not-found" component={Page404} />
              <ScrollToTopRoute exact path="/promos" component={Promos} />
              <ScrollToTopRoute
                exact
                path="/promos/provider/:id"
                component={CuponsProviderFullView}
              />
              <ScrollToTopRoute
                exact
                path="/providers"
                component={ProvidersSearchList}
              />
              <ScrollToTopRoute
                exact
                path="/providers/provider/:id"
                component={ProviderFullView}
              />
              <ScrollToTopRoute
                path="/providers/provider/product/:id"
                component={ProductFullView}
              />
              <ScrollToTopRoute path="/purchase-cart" component={Cart} />
            </Suspense>
          </Switch>
          <div
            className="fixed-bottom"
            style={{
              marginBottom: 8,
              padding: '0 8px',
            }}
          >
            <Footer />
          </div>
        </div>
      </div>
    </Router>
  );
};

export default App;
