/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

// Bootstrap Imports
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import Modal from 'react-bootstrap/Modal';

// Redux Imoorts
import {
  locationPermissionsDenegated,
  setShowStartModal,
  setUserLocation,
} from '../../store/actions/locationActions';

// Local Impots
import LocationPermission from './LocationPermission';
import CategorySelectPage from './CategorySelectPage';

const StartScreen = (props) => {
  // Props Destructuring
  const {
    locationPermissionsDenegated,
    showStartModal,
    setShowStartModal,
    userLocation,
    setUserLocation,
  } = props;

  // Component State
  const [loading, setLoading] = useState(false);

  // Component Hooks
  useEffect(() => {
    // navigator.permissions
    //   .query({ name: 'geolocation' })
    //   .then((permissionStatus) => {
    //     if (permissionStatus.state === 'granted') {
    //       getLocation();
    //     } else if (permissionStatus.state === 'denied') {
    //       locationPermissionsDenegated();
    //     } else {
    //       setShowStartModal();
    //     }
    //   });
    if (Object.keys(userLocation).length === 0) {
      setShowStartModal();
    } else getLocation();
  }, []);

  // Component Functions
  const getCoordinates = (position) => {
    setUserLocation(position);
    setLoading(false);
  };

  // eslint-disable-next-line no-shadow
  const handleLocationError = (error) => {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        alert(
          'Has denegado los permisos de Geolocalización. Estos son Nesesarios para el correcto funcionamiento de Veci.'
        );
        locationPermissionsDenegated();
        setLoading(false);
        break;
      case error.POSITION_UNAVAILABLE:
        alert('La información de Geolocalización no está disponible');
        setLoading(false);
        break;
      case error.TIMEOUT:
        alert('La solicitud de geolocalización ha caducado');
        setLoading(false);
        break;
      case error.UNKNOWN_ERROR:
        alert('Se ha producido un error desconocido');
        setLoading(false);
        break;
      default:
        break;
    }
  };

  const getLocation = () => {
    // console.log('getLocation was called');
    setLoading(true);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        getCoordinates,
        handleLocationError
      );
    } else {
      alert('La geolocalización no es soportada en éste navegador');
    }
  };

  return (
    <Modal
      size="lg"
      centered
      // scrollable
      show={showStartModal}
      dialogClassName="start-modal"
      style={{ height: '100%' }}
    >
      <Modal.Body className="d-flex flex-column text-center justify-content-center">
        <LocationPermission />
        {/* <CategorySelectPage /> */}
      </Modal.Body>
      <Modal.Footer>
        <Button
          block
          variant="primary"
          disabled={!!(loading && Object.keys(userLocation).length === 0)}
          onClick={getLocation}
        >
          {loading && Object.keys(userLocation).length === 0
            ? 'Obteniendo ubicación...'
            : 'Continuar'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    userLocation: state.location.userLocation,
    showStartModal: state.location.showStartModal,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setShowStartModal: (userLocation) =>
      dispatch(setShowStartModal(userLocation)),
    setUserLocation: (position) => dispatch(setUserLocation(position)),

    locationPermissionsDenegated: () =>
      dispatch(locationPermissionsDenegated()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StartScreen);
