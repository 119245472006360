const initState = {
  productDelivery: '',
  category: '',
  products: [],
  startProducts: null,
  loading: false,
  reloadProducts: false,
  searchResults: '',
};

const searchReducer = (state = initState, action) => {
  switch (action.type) {
    case 'START_FILTER_PARAMETERS':
      return {
        ...state,
        loading: true,
      };
    case 'SET_FILTER_PARAMETERS':
      return {
        ...state,
        productType: action.payload.productType,
        productDelivery: action.payload.productDelivery,
        category: action.payload.category,
        loading: false,
        reloadProducts: true,
        products: [],
      };
    case 'RESET_FILTER_PARAMETERS':
      return {
        ...state,
        productDelivery: '',
        category: '',
        reloadProducts: true,
        products: [],
      };
    case 'SET_UPDATE_PRODUCTS':
      return {
        ...state,
        products: [...state.products, ...action.payload],
      };
    case 'SET_START_PRODUCTS':
      return {
        ...state,
        startProducts: action.payload,
      };
    case 'SET_RELOAD_PRODUCTS':
      return {
        ...state,
        reloadProducts: false,
      };
    case 'SET_SEARCH_STRING':
      console.log(action.payload);

      return {
        ...state,
        loading: false,
        searchResults: action.payload,
      };

    default:
      return state;
  }
};

export default searchReducer;
