/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import ReactDOM from 'react-dom';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { PersistGate } from 'redux-persist/integration/react';

// import initWorkboxRefresh from '@loopmode/cra-workbox-refresh';

import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import { Global, css } from '@emotion/core';

import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';

import { reduxFirestore, getFirestore } from 'redux-firestore';
import { reactReduxFirebase, getFirebase } from 'react-redux-firebase';
import rootReducer from './store/reducers/rootReducer';
import App from './App';
import fbConfig from './config/fbConfig';

import * as serviceWorker from './serviceWorker';

const reduxDevTools =
  typeof window === 'object' &&
  typeof window.__REDUX_DEVTOOLS_EXTENSION__ !== 'undefined'
    ? window.__REDUX_DEVTOOLS_EXTENSION__()
    : (f) => f;

const persistConfig = {
  key: 'root',
  storage,
  blacklist: [
    'auth',
    'publicProviders',
    'search',
    'firestore',
    'provider',
    'project',
    'course',
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(
  persistedReducer,
  compose(
    applyMiddleware(thunk.withExtraArgument({ getFirebase, getFirestore })),
    reduxFirestore(fbConfig), // redux bindings for firestore
    reactReduxFirebase(fbConfig, {
      useFirestoreForProfile: true,
      userProfile: 'users',
      attachAuthIsReady: true,
    }), // redux binding for firebase
    reduxDevTools
  )
);

const persistor = persistStore(store);

store.firebaseAuthIsReady.then(() => {
  ReactDOM.render(
    <>
      <Global
        styles={css`
          :root {
            --primary: #ea7d31; /** Naranja */
            --primaryImportant: #ff5e00; /** Naranja obscuro */
            --primaryHover: #ff934b; /** Naranja claro */
            --navBtnActive: #d2651a; /** Naranja claro */
            --secondary: #3973b9; /** Azul */
            --secondaryHover: #609de6; /** Naranja claro */
            --primaryText: #263843; /** Azul grisaseo */
            --primaryBtnText: #fff; /** Blanco */
            --secondaryBackground: #edf2f7; /** Celeste pálido */
            --dangerBackground: #ffdbdc; /** Rosa pálido */
            --dangerText: #ed383a; /** Rojo fuerte */
          }
          input:focus,
          textarea:focus,
          select:focus,
          .react-tagsinput:focus {
            outline: 0;
            box-shadow: 0 0 0 0.2rem rgba(210, 101, 26, 0.45) !important;
          }

          a:focus {
            box-shadow: none !important;
          }

          .react-tagsinput-input:focus {
            border: 1px solid var(--primary) !important;
            box-shadow: none !important;
            border-radius: 5px;
          }

          input[type='checkbox']:focus,
          .react-share__ShareButton:focus,
          .react-share__ShareButton:active {
            outline: 0;
            box-shadow: none !important;
          }

          .steps-card:focus,
          li:focus {
            outline: 0 !important;
          }

          .form-control:focus {
            border-color: var(--primary) !important;
          }

          button:focus,
          button:active {
            outline: 0 !important;
            box-shadow: 0 0 0 0.2rem rgba(210, 101, 26, 0.45) !important;
          }

          .btn-secondary-brand-color:focus,
          .btn-secondary-brand-color:active {
            outline: 0 !important;
            box-shadow: none !important;
          }

          .btn-cancel:hover,
          .btn-cancel:focus {
            outline: 0 !important;
            box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, 0.45) !important;
          }
          a {
            color: var(--secondary);
            text-decoration: none;
            background-color: transparent;
          }
          a:hover {
            text-decoration: none !important;
          }
          html {
            scroll-behavior: smooth;
          }
        `}
      />
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </>,
    document.getElementById('root')
  );

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.register();
});
