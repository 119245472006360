const initState = {
	subscribers: [],
	loading: false,
};

const subscriberReduder = (state = initState, action) => {
	switch (action.type) {
		case 'CREATE_SUBSCRIBER_START':
			return {
				...state,
				loading: true,
			};
		case 'CREATE_SUBSCRIBER':
			console.log('Subscriptor añadido', action.subscriber);
			return {
				...state,
				loading: false,
			};
		case 'CREATE_SUBSCRIBER_ERROR':
			console.log('Error añdaiendo subscriptor', action.err);
			return {
				...state,
				loading: false,
			};
		default:
			return state;
	}
};

export default subscriberReduder;
