import React from 'react';

// Bootstrap Imports
import Image from 'react-bootstrap/Image';

const LocationPermission = () => {
  return (
    <>
      <div className="my-3">
        <span
          style={{ fontSize: 30, color: 'var(--secondary)', fontWeight: 500 }}
        >
          Localización
        </span>
      </div>
      <div>
        <Image src="/img/mapa.png" width={300} />
      </div>
      <div className="p-3 mt-2">
        <span style={{ color: '#555555' }}>
          Para encontrar los negocios más cercanos a ti, ésta aplicación
          necesita utilizar la localización de tu dispositivo.
        </span>
      </div>
      <div className="mt-3">
        <Image src="/img/logo-texto.png" width={120} />
      </div>
    </>
  );
};

export default LocationPermission;
