const initState = {
  reviews: [],
};

const reviewReducer = (state = initState, action) => {
  switch (action.type) {
    case 'CREATE_REVIEW':
      console.log('Experiencia creada', action.review);
      return state;
    case 'CREATE_REVIEW_ERROR_':
      console.log('create review error', action.err);
      return state;
    default:
      return state;
  }
};

export default reviewReducer;
